
<template>
  <div id="box">
    <h1>脑震荡后综合征问卷</h1>
    <p>请根据您最近的身体情况，选择最符合的答案。</p>
    <div>
      <h4>1.头痛</h4>
      <el-radio-group v-model="radio">
        <el-radio label="0">A：无</el-radio><br />
        <el-radio label="1">B：轻微 </el-radio><br />
        <el-radio label="2">C：轻度</el-radio><br />
        <el-radio label="3">D: 中度</el-radio><br />
        <el-radio label="4">E: 重度</el-radio><br /> </el-radio-group
      ><br />
      <h4>2.头晕</h4>
      <el-radio-group v-model="radio1">
        <el-radio label="0">A：无</el-radio><br />
        <el-radio label="1">B：轻微 </el-radio><br />
        <el-radio label="2">C：轻度</el-radio><br />
        <el-radio label="3">D: 中度</el-radio><br />
        <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
      ><br />
      <br />
      <h4>3.恶心或呕吐</h4>
      <el-radio-group v-model="radio2">
        <el-radio label="0">A：无</el-radio><br />
        <el-radio label="1">B：轻微 </el-radio><br />
        <el-radio label="2">C：轻度</el-radio><br />
        <el-radio label="3">D: 中度</el-radio><br />
        <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
      ><br />
      <br />
      <h4>4.对声音敏感</h4>
      <el-radio-group v-model="radio3">
        <el-radio label="0">A：无</el-radio><br />
        <el-radio label="1">B：轻微 </el-radio><br />
        <el-radio label="2">C：轻度</el-radio><br />
        <el-radio label="3">D: 中度</el-radio><br />
        <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
      ><br />
      <br />
    </div>
    <br />
    <h4>5.睡眠障碍</h4>
    <el-radio-group v-model="radio4">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>6.容易疲劳</h4>
    <el-radio-group v-model="radio5">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>7.容易生气</h4>
    <el-radio-group v-model="radio6">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>8.心情不好或悲伤</h4>
    <el-radio-group v-model="radio7">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>9.感觉失落或烦躁</h4>
    <el-radio-group v-model="radio8">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>10.健忘</h4>
    <el-radio-group v-model="radio9">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>11.注意力不集中</h4>
    <el-radio-group v-model="radio10">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>12.思考问题较慢</h4>
    <el-radio-group v-model="radio11">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>13.视物模糊</h4>
    <el-radio-group v-model="radio12">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>14.对光敏感</h4>
    <el-radio-group v-model="radio13">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>15.复视</h4>
    <el-radio-group v-model="radio14">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <h4>16.不安</h4>
    <el-radio-group v-model="radio15">
      <el-radio label="0">A：无</el-radio><br />
      <el-radio label="1">B：轻微 </el-radio><br />
      <el-radio label="2">C：轻度</el-radio><br />
      <el-radio label="3">D: 中度</el-radio><br />
      <el-radio label="4">E: 重度</el-radio><br /></el-radio-group
    ><br />
    <el-button type="success" @click="ExamSolutionADD" :disabled="isdisabled"
      >提交</el-button
    >
  </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
  mounted() {
    this.get();
  },
  updated() {
    // console.log("总分：", this.total);
    // console.log("说明", this.explain);
  },
  methods: {
    async ExamSolutionADD() {
      const data = await axios({
        url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=脑震荡后综合征问卷&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=/&Review=暂无建议&Section=/&Doctor=/`,
        method: "post",
        data,
      });
      if (data.data.data == true) {
        this.isShow = !this.isShow;
        this.$router.push("/Assessment");
      }
    },
    //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
    get() {
      const patientN = localStorage.getItem("patientN");
      const patientI = localStorage.getItem("patientI");
      this.name = JSON.parse(patientN);
      this.cid = JSON.parse(patientI);
    },
  },
  computed: {
    isdisabled() {
      if (
        this.radio == "" ||
        this.radio1 == "" ||
        this.radio2 == "" ||
        this.radio3 == "" ||
        this.radio4 == "" ||
        this.radio5 == "" ||
        this.radio6 == "" ||
        this.radio7 == "" ||
        this.radio8 == "" ||
        this.radio9 == "" ||
        this.radio10 == "" ||
        this.radio11 == "" ||
        this.radio12 == "" ||
        this.radio13 == "" ||
        this.radio14 == "" ||
        this.radio15 == ""
      ) {
        return true;
      }
      if (
        this.radio != "" ||
        this.radio1 != "" ||
        this.radio2 != "" ||
        this.radio3 != "" ||
        this.radio4 != "" ||
        this.radio5 != "" ||
        this.radio6 != "" ||
        this.radio7 != "" ||
        this.radio8 != "" ||
        this.radio9 != "" ||
        this.radio10 != "" ||
        this.radio11 != "" ||
        this.radio12 != "" ||
        this.radio13 != "" ||
        this.radio14 != "" ||
        this.radio15 != ""
      ) {
        return false;
      }
    },
    total() {
      return (
        this.radio * 1 +
        this.radio1 * 1 +
        this.radio2 * 1 +
        this.radio3 * 1 +
        this.radio4 * 1 +
        this.radio5 * 1 +
        this.radio6 * 1 +
        this.radio7 * 1 +
        this.radio8 * 1 +
        this.radio9 * 1 +
        this.radio10 * 1 +
        this.radio11 * 1 +
        this.radio12 * 1 +
        this.radio13 * 1 +
        this.radio14 * 1 +
        this.radio15 * 1
      );
    },
  },
  data() {
    return {
      ETID: "E10",
      isShow: false,
      name: "",
      cid: "",
      radio: "",
      radio1: "",
      radio2: "",
      radio3: "",
      radio4: "",
      radio5: "",
      radio6: "",
      radio7: "",
      radio8: "",
      radio9: "",
      radio10: "",
      radio11: "",
      radio12: "",
      radio13: "",
      radio14: "",
      radio15: "",
    };
  },
};
</script>

<style lang='scss' scoped>
// @import "./style.scss";
.el-radio {
  margin: 10px;
}
body {
  background: rgb(220, 245, 179);
}
#box {
  width: 100%;
  margin: auto;
  text-align: left;
  margin-left: 30px;
  // background: rgb(206, 245, 175);
}
.label_color {
  color: rgb(202, 44, 207);
}
h1 {
  text-align: center;
}

h4 {
  color: rgb(140, 102, 245);
}
</style>

